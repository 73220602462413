import gql from "graphql-tag";

export const StoreSearchHistory = gql`
  mutation StoreSearchHistoryMutation(
    $id: Int!
    $searchText: String!
    $userid: String!
    $template: Boolean!
  ) {
    storeSearchHistory(
      id: $id
      userid: $userid
      searchText: $searchText
      template: $template
    ) {
      id
      userid
      template
      searchText
      dateCreated
    }
  }
`;

export const DeleteUserSearchHistory = gql`
  mutation DeleteUserSearchHistoryMutation(
    $id: String!
    $userid: String!
    $type: String!
  ) {
    deleteUserSearchHistory(id: $id, userid: $userid, type: $type) {
      id
      userid
      template
      searchText
      dateCreated
    }
  }
`;

export const DoLogin = gql`
  mutation DoLoginMutation($emailid: String!, $password: String!) {
    doLogin(emailid: $emailid, password: $password) {
      userid
      emailid
      firstName
      token
      surName
      companytype
      title
      companyname
      accountId
      countrycode
      dimensionUnit
      volumeUnit
      weightUnit
      countryName
      usertype
      userlevel
      currency
      loginAttempt
    }
  }
`;

export const DoRegistration = gql`
  mutation DoRegistrationMutation(
    $airlinesOnBoard: String!
    $cassNo: String!
    $companyname: String!
    $companyWebsite: String!
    $countrycode: String!
    $emailid: String!
    $globalEmailid: String!
    $firstname: String!
    $iataNo: String!
    $password: String!
    $phonenumber: String!
    $surname: String!
    $companytype: String!
    $title: String!
    $fiataNo: String!
    $agentCode: String!
  ) {
    doRegistration(
      airlinesOnBoard: $airlinesOnBoard
      cassNo: $cassNo
      companyname: $companyname
      companyWebsite: $companyWebsite
      countrycode: $countrycode
      emailid: $emailid
      globalEmailid: $globalEmailid
      firstname: $firstname
      iataNo: $iataNo
      password: $password
      phonenumber: $phonenumber
      surname: $surname
      companytype: $companytype
      title: $title
      fiataNo: $fiataNo
      agentCode: $agentCode
    )
  }
`;

export const StoreBookingHistory = gql`
  mutation StoreBookingHistoryMutation(
    $booking: Boolean!
    $searchText: String!
    $userid: String!
    $id: Int!
    $tariffid: Int!
    $rate: String!
    $domain: String!
  ) {
    storeBookingHistory(
      booking: $booking
      searchText: $searchText
      userid: $userid
      id: $id
      tariffid: $tariffid
      rate: $rate
      domain: $domain
    ) {
      booking
      id
      searchText
      userid
      rate
      domain
    }
  }
`;

export const UploadCsv = gql`
  mutation UploadCsvMutation(
    $file: Upload!
    $id: Int!
    $type: String!
    $filename: String!
  ) {
    readFile(file: $file, id: $id, type: $type, filename: $filename)
  }
`;

export const UploadFlightsCsv = gql`
  mutation UploadFlightsCsvMutation($file: Upload!, $id: Int!, $type: String!) {
    flightReadFile(file: $file, id: $id, type: $type)
  }
`;

export const UpdateAirline = gql`
  mutation UpdateAirlineMutation($airlineList: String!, $userid: Int!) {
    updateAirline(airlineList: $airlineList, userid: $userid) {
      accountId
      airlines
      statusCode
      msg
    }
  }
`;

export const ratePerCharge = gql`
  mutation ratePerChargeMutation($data: String!, $id: Int!) {
    ratePerCharge(data: $data, id: $id)
  }
`;

export const BookingStatusDeclineUpdate = gql`
  mutation BookingStatusDeclineMutation(
    $bookingId: Int!
    $statusCode: Int!
    $reasonCode: Int!
    $otherReason: String!
    $domain: String!
  ) {
    bookingStatusUpdate(
      bookingId: $bookingId
      statusCode: $statusCode
      reasonCode: $reasonCode
      otherReason: $otherReason
      domain: $domain
    )
  }
`;

export const BookingStatusUpdate = gql`
  mutation BookingStatusUpdateMutation(
    $bookingId: Int!
    $statusCode: Int!
    $domain: String!
    $otherReason: String!
    $awbForwarderCode: String!
  ) {
    bookingStatusUpdate(
      bookingId: $bookingId
      statusCode: $statusCode
      domain: $domain
      otherReason: $otherReason
      awbForwarderCode: $awbForwarderCode
    )
  }
`;

export const DoVerification = gql`
  mutation doVerificationMutation($token: String!) {
    doVerification(token: $token) {
      isVerified
      message
    }
  }
`;

export const ChangeStatus = gql`
  mutation ChangeStatusMutation(
    $status: String!
    $userid: Int!
    $domain: String!
  ) {
    changeStatus(status: $status, userid: $userid, domain: $domain) {
      accountId
      active
      airlines
      airlineList
      cassNo
      companyname
      companytype
      dateCreated
      emailid
      firstname
      iataNo
      lastUpdated
      phonenumber
      status
      userid
      usertype
      surname
      fiataNo
      domain
    }
  }
`;

export const DeleteAwbstock = gql`
  mutation DeleteAwbstockMutation($awbstockid: Int!, $userid: Int!) {
    deleteAwbstock(awbstockid: $awbstockid, userid: $userid) {
      awbStatus
      awbno
      awbstockid
      carrierCode
      dateCreated
      lastUpdated
    }
  }
`;

export const AwbStockData = gql`
  mutation AddAwbstockDataMutation(
    $airlineId: String!
    $awbno: String!
    $awbPrefix: String!
    $quantity: String!
    $userid: String!
  ) {
    addAwbstockData(
      airlineId: $airlineId
      awbno: $awbno
      awbPrefix: $awbPrefix
      quantity: $quantity
      userid: $userid
    ) {
      awbstockid
      awbno
      awbStatus
      dateCreated
      lastUpdated
      carrierCode
      version
    }
  }
`;

export const ConfirmShipmentBooking = gql`
  mutation ConfirmShipmentBookingMutation(
    $airlineId: String!
    $id: Int!
    $userid: Int!
    $status: String!
    $domain: String!
    $awbno: String!
  ) {
    confirmShipmentBooking(
      airlineId: $airlineId
      id: $id
      userid: $userid
      status: $status
      domain: $domain
      awbno: $awbno
    )
  }
`;

export const ConfirmedMailForwarder = gql`
  mutation ConfirmedMailForwarderMutation($bookingid: Int!) {
    confirmedMailForwarder(bookingid: $bookingid)
  }
`;

export const UpdateBookingDetails = gql`
  mutation UpdateBookingDetailsMutation(
    $id: Int!
    $rate: String!
    $searchText: String!
    $status: Int!
    $userid: Int!
    $type: String!
    $domain: String!
    $deliverTo: String!
    $location: String!
    $deliveryDate: String!
    $cutoffTime: String!
  ) {
    updateBookingDetails(
      id: $id
      rate: $rate
      searchText: $searchText
      status: $status
      userid: $userid
      type: $type
      domain: $domain
      deliverTo: $deliverTo
      location: $location
      deliveryDate: $deliveryDate
      cutoffTime: $cutoffTime
    ) {
      accountId
      actionStatus
      awbno
      booking
      dateCreated
      frightagent
      id
      quotesStatus
      rate
      searchText
      status
      totalCount
      userid
      domain
    }
  }
`;

export const EditUser = gql`
  mutation EditUserMutation(
    $cassNo: String!
    $companyname: String!
    $emailid: String!
    $firstname: String!
    $iataNo: String!
    $phonenumber: String!
    $status: String!
    $surname: String!
    $userid: Int!
    $verifiedBy: Int!
    $accountId: Int!
    $fiataNo: String!
    $accountstatus: String!
    $domain: String!
  ) {
    editUser(
      cassNo: $cassNo
      companyname: $companyname
      emailid: $emailid
      firstname: $firstname
      iataNo: $iataNo
      phonenumber: $phonenumber
      status: $status
      surname: $surname
      userid: $userid
      verifiedBy: $verifiedBy
      accountId: $accountId
      fiataNo: $fiataNo
      accountstatus: $accountstatus
      domain: $domain
    ) {
      accountId
      active
      airlines
      cassNo
      companyname
      companytype
      country
      countrycode
      dashboardUrl
      dateCreated
      emailid
      firstname
      iataNo
      lastUpdated
      phonenumber
      status
      surname
      userid
      usertype
      fiataNo
      accountStatus
      domain
    }
  }
`;

export const EditFlightDetailsData = gql`
  mutation EditFlightDetailsDataMutation(
    $aircraftType: String!
    $destination: String!
    $flightdate: String!
    $flightId: Int!
    $origin: String!
    $stdArrivalTime: String!
    $stdDepartureTime: String!
    $wtcapacity: String!
  ) {
    editFlightDetails(
      aircraftType: $aircraftType
      destination: $destination
      flightdate: $flightdate
      flightId: $flightId
      origin: $origin
      stdArrivalTime: $stdArrivalTime
      stdDepartureTime: $stdDepartureTime
      wtcapacity: $wtcapacity
    ) {
      flightdetailId
      flightNumber
      flightdate
      departureStation
      stdDepartureTime
      arrivalStation
      stdArrivalTime
      aircraftType
      wtcapacity
      lastUpdated
      totalCount
    }
  }
`;

// export const UploadSSIMData = gql`
//   mutation uploadSsimData($file: Upload!, $fileName: String!) {
//     uploadSsimData(file: $file, fileName: $id)
//   }
// `;

export const UploadSSIMFile = gql`
  mutation ssimFile($file: Upload!, $id: Int!) {
    ssimFile(file: $file, id: $id)
  }
`;

export const DeleteFlightDetails = gql`
  mutation deleteFlightDetail($ssimhdrId: Int!) {
    deleteFlightDetail(ssimhdrId: $ssimhdrId)
  }
`;

export const TruncateFlightDetails = gql`
  mutation truncateFlightDetail(
    $flightDate: String!
    $ssimhdrId: String!
    $validTo: Int!
  ) {
    truncateFlightDetail(
      flightDate: $flightDate
      ssimhdrId: $ssimhdrId
      validTo: $validTo
    )
  }
`;

export const LoadFlightDetails = gql`
  mutation loadFlightDetail($ssimHeaderId: Int!) {
    loadFlightDetail(ssimHeaderId: $ssimHeaderId)
  }
`;

export const DeleteFlightDetailById = gql`
  mutation deleteFlightDetailById($flightdetailId: Int!) {
    deleteFlightDetailById(flightdetailId: $flightdetailId) {
      airlineName
      aircraftType
      arrivalStation
      dateCreated
      departureStation
      stdDepartureTime
      stdArrivalTime
      wtcapacity
      legSequenceNumber
      lastUpdated
      flightdetailId
      flightdate
      flightNumber
      flight
      totalCount
    }
  }
`;

export const AddUser = gql`
  mutation AddUserMutation(
    $gssaUserid: String!
    $emailid: String!
    $firstname: String!
    $surname: String!
    $password: String!
    $phonenumber: String!
    $iataNo: String!
    $cassNo: String!
    $title: String!
    $fiataNo: String!
    $usertype: String!
    $userlevel: Int!
    $countryCode: String!
    $activeStatus: String!
    $globalEmailid: String!
  ) {
    addUser(
      gssaUserid: $gssaUserid
      emailid: $emailid
      globalEmailid: $globalEmailid
      firstname: $firstname
      surname: $surname
      password: $password
      phonenumber: $phonenumber
      iataNo: $iataNo
      cassNo: $cassNo
      title: $title
      fiataNo: $fiataNo
      usertype: $usertype
      userlevel: $userlevel
      countryCode: $countryCode
      activeStatus: $activeStatus
    ) {
      userid
    }
  }
`;

export const EmailUpdate = gql`
  mutation EmailUpdateMutation($userid: Int!, $email: String!) {
    emailUpdate(userid: $userid, email: $email)
  }
`;
export const PhoneNumberUpdate = gql`
  mutation PhoneNumberUpdateMutation(
    $userid: Int!
    $phoneNumber: String!
    $countrycode: String!
  ) {
    phoneNumberUpdate(
      userid: $userid
      phoneNumber: $phoneNumber
      countrycode: $countrycode
    )
  }
`;

export const EditGssaUser = gql`
  mutation EditGssaUserMutation(
    $emailid: String!
    $firstname: String!
    $surname: String!
    $password: String!
    $countrycode: String!
    $phonenumber: String!
    $iataNo: String!
    $cassNo: String!
    $title: String!
    $fiataNo: String!
    $userid: Int!
    $accountStatus: String!
    $globalEmailid: String!
    $userlevel: String!
  ) {
    editGssaUser(
      accountStatus: $accountStatus
      cassNo: $cassNo
      countrycode: $countrycode
      emailid: $emailid
      fiataNo: $fiataNo
      firstname: $firstname
      globalEmailid: $globalEmailid
      iataNo: $iataNo
      phonenumber: $phonenumber
      surname: $surname
      userlevel: $userlevel
      userid: $userid
      password: $password
      title: $title
    ) {
      userid
    }
  }
`;

export const ChangePasswordMutation = gql`
  mutation ChangePasswordMutation(
    $currentpassword: String!
    $newPassword: String!
    $userid: Int!
  ) {
    changePassword(
      currentpassword: $currentpassword
      newPassword: $newPassword
      userid: $userid
    )
  }
`;

export const EmailVerification = gql`
  mutation emailVerificationMutation($email: String!) {
    emailVerification(email: $email)
  }
`;

export const OtpVerification = gql`
  mutation otpVerificationMutation($otp: String!) {
    otpVerification(otp: $otp)
  }
`;

export const ForgetUserPassword = gql`
  mutation forgetUserPasswordMutation(
    $email: String!
    $otp: String!
    $password: String!
  ) {
    forgetUserPassword(email: $email, otp: $otp, password: $password)
  }
`;

export const AdminAddUser = gql`
  mutation AdminAddUserMutation(
    $adminUserid: String!
    $emailid: String!
    $firstname: String!
    $surname: String!
    $password: String!
    $phonenumber: String!
    $iataNo: String!
    $cassNo: String!
    $title: String!
    $usertype: String!
    $countryCode: String!
    $activeStatus: String!
  ) {
    adminAddUser(
      adminUserid: $adminUserid
      emailid: $emailid
      firstname: $firstname
      surname: $surname
      password: $password
      phonenumber: $phonenumber
      iataNo: $iataNo
      cassNo: $cassNo
      title: $title
      usertype: $usertype
      countryCode: $countryCode
      activeStatus: $activeStatus
    ) {
      userid
    }
  }
`;

export const EditAdminUser = gql`
  mutation EditAdminUserMutation(
    $emailid: String!
    $firstname: String!
    $surname: String!
    $password: String!
    $countrycode: String!
    $phonenumber: String!
    $iataNo: String!
    $cassNo: String!
    $title: String!
    $userid: Int!
    $accountStatus: String!
    $userType: String!
  ) {
    editAdminUser(
      accountStatus: $accountStatus
      cassNo: $cassNo
      countrycode: $countrycode
      emailid: $emailid
      firstname: $firstname
      iataNo: $iataNo
      phonenumber: $phonenumber
      surname: $surname
      userid: $userid
      password: $password
      title: $title
      userType: $userType
    ) {
      userid
    }
  }
`;

export const AddFedagsaUser = gql`
  mutation AddFedagsaUserMutation(
    $companyName: String!
    $countryCode: String!
    $status: String!
  ) {
    addFedagsaUser(
      companyName: $companyName
      countryCode: $countryCode
      status: $status
    ) {
      gssaId
    }
  }
`;

export const DeclineUserMutation = gql`
  mutation DeclineUserMutation(
    $userid: Int!
    $reason: String!
    $declineByUserid: Int!
  ) {
    declineUser(
      userid: $userid
      reason: $reason
      declineByUserid: $declineByUserid
    )
  }
`;

export const DeleteFedagsaUser = gql`
  mutation deleteFedagsaUser($gssaId: String!) {
    deleteFedagsaUser(gssaId: $gssaId)
  }
`;

export const UpdateFedagsaUser = gql`
  mutation UpdateFedagsaUserMutation(
    $countryCode: String!
    $companyname: String!
    $gssaId: String!
    $status: String!
  ) {
    updateFedagsaUser(
      status: $status
      countryCode: $countryCode
      companyname: $companyname
      gssaId: $gssaId
    )
  }
`;

export const UpdateTariffData = gql`
  mutation UpdateTariffDataMutation(
    $tariffid: Int!
    $accountId: Int!
    $companyname: String!
    $airlineName: String!
    $origin: String!
    $viaAirport: String!
    $destination: String!
    $commodity: String!
    $endDate: String!
    $minimum: Float!
    $rateperunit: Float!
    $w45: Float!
    $w100: Float!
    $w300: Float!
    $w500: Float!
    $w1000: Float!
    $w3000: Float!
    $ratetype: String!
    $agentCode: String!
    $spotNo: String!
    $currency: String!
  ) {
    updateTariffData(
      tariffid: $tariffid
      accountId: $accountId
      companyname: $companyname
      airlineName: $airlineName
      origin: $origin
      viaAirport: $viaAirport
      destination: $destination
      commodity: $commodity
      endDate: $endDate
      minimum: $minimum
      rateperunit: $rateperunit
      w45: $w45
      w100: $w100
      w300: $w300
      w500: $w500
      w1000: $w1000
      w3000: $w3000
      ratetype: $ratetype
      agentCode: $agentCode
      spotNo: $spotNo
      currency: $currency
    ) {
      tariffid
    }
  }
`;

export const InsertTariffData = gql`
  mutation InsertTariffDataMutation(
    $accountId: Int!
    $airlineName: String!
    $origin: String!
    $viaAirport: String!
    $destination: String!
    $commodity: String!
    $countrycode: String!
    $minimum: Float!
    $rateperunit: Float!
    $w45: Float!
    $w100: Float!
    $w300: Float!
    $w500: Float!
    $w1000: Float!
    $w3000: Float!
    $ratetype: String!
    $agentCode: String!
    $spotNo: String!
    $endDate: String!
    $startDate: String!
    $currency: String!
  ) {
    insertTariffData(
      accountId: $accountId
      airlineName: $airlineName
      origin: $origin
      viaAirport: $viaAirport
      destination: $destination
      commodity: $commodity
      countrycode: $countrycode
      minimum: $minimum
      rateperunit: $rateperunit
      w45: $w45
      w100: $w100
      w300: $w300
      w500: $w500
      w1000: $w1000
      w3000: $w3000
      ratetype: $ratetype
      agentCode: $agentCode
      spotNo: $spotNo
      endDate: $endDate
      startDate: $startDate
      currency: $currency
    )
  }
`;

export const DeleteTariffData = gql`
  mutation deleteTariffData($tariffid: Int!) {
    deleteTariffData(tariffid: $tariffid)
  }
`;

export const ChangeUserType = gql`
  mutation ChangeUserTypeMutation($userid: Int!, $usertype: String!) {
    changeUserType(userid: $userid, usertype: $usertype)
  }
`;

export const UploadProfileImage = gql`
  mutation UploadProfileImageMutation(
    $imgdata: String!
    $companyname: String!
  ) {
    uploadProfileImage(imgdata: $imgdata, companyname: $companyname)
  }
`;

export const InsertFlightDetails = gql`
  mutation InsertFlightDetailsMutation(
    $userId: String!
    $accountId: String!
    $airline: String!
    $flight: String!
    $startDate: String!
    $endDate: String!
    $dayReq: String!
    $aircraftType: String!
    $origin: String!
    $destination: String!
    $stddeparturetime: String!
    $stdarrivaltime: String!
    $arrivalDate: String!
    $wtcapacity: String!
    $volcapacity: String!
    $isActive: String!
  ) {
    insertFlightDetails(
      userId: $userId
      accountId: $accountId
      airline: $airline
      flight: $flight
      startDate: $startDate
      endDate: $endDate
      dayReq: $dayReq
      aircraftType: $aircraftType
      origin: $origin
      destination: $destination
      stddeparturetime: $stddeparturetime
      stdarrivaltime: $stdarrivaltime
      arrivalDate: $arrivalDate
      wtcapacity: $wtcapacity
      volcapacity: $volcapacity
      isActive: $isActive
    )
  }
`;

export const DeleteFlightDetailsById_ = gql`
  mutation deleteFlightDetailsById_($flightdetailId: String!) {
    deleteFlightDetailsById_(flightdetailId: $flightdetailId)
  }
`;

export const UpdateFlightDetails = gql`
  mutation UpdateFlightDetailsMutation(
    $flightdetailId: String!
    $airline: String!
    $flight: String!
    $flightDate: String!
    $aircraftType: String!
    $origin: String!
    $destination: String!
    $stddeparturetime: String!
    $stdarrivaltime: String!
    $arrivalDate: String!
    $wtcapacity: String!
    $volcapacity: String!
    $isActive: String!
  ) {
    updateFlightDetails(
      flightdetailId: $flightdetailId
      airline: $airline
      flight: $flight
      flightDate: $flightDate
      aircraftType: $aircraftType
      origin: $origin
      destination: $destination
      stddeparturetime: $stddeparturetime
      stdarrivaltime: $stdarrivaltime
      arrivalDate: $arrivalDate
      wtcapacity: $wtcapacity
      volcapacity: $volcapacity
      isActive: $isActive
    )
  }
`;

export const CancelSpot = gql`
  mutation CancelSpotMutation($tariffId: Int!) {
    cancelSpot(tariffId: $tariffId)
  }
`;

export const DoInvoice = gql`
  mutation DoInvoiceMutation($date: String!, $domain: String!) {
    doInvoice(date: $date, domain: $domain)
  }
`;

export const UpdateAddress = gql`
  mutation UpdateAddressMutation(
    $accountId: Int!
    $street: String!
    $city: String!
    $postalCode: String!
    $country: String!
    $cassNo: String!
  ) {
    updateAddress(
      accountId: $accountId
      street: $street
      city: $city
      postalCode: $postalCode
      country: $country
      cassNo: $cassNo
    )
  }
`;

export const PayBills = gql`
  mutation PayBillsMutation($invoiceId: Int!) {
    payBills(invoiceId: $invoiceId)
  }
`;

export const InsertDeliveryPoint = gql`
  mutation InsertDeliveryPointMutation(
    $accountId: Int!
    $airlines: String!
    $airport: String!
    $gha: String!
    $location: String!
  ) {
    insertDeliveryPoint(
      accountId: $accountId
      airlines: $airlines
      airport: $airport
      gha: $gha
      location: $location
    )
  }
`;

export const EditDeliveryPoint = gql`
  mutation EditDeliveryPointMutation(
    $accountId: Int!
    $airlines: String!
    $airport: String!
    $dlvId: Int!
    $gha: String!
    $location: String!
  ) {
    editDeliveryPoint(
      accountId: $accountId
      airlines: $airlines
      airport: $airport
      dlvId: $dlvId
      gha: $gha
      location: $location
    )
  }
`;

export const DeleteDeliveryPoint = gql`
  mutation DeleteDeliveryPointMutation($dlvId: Int!) {
    deleteDeliveryPoint(dlvId: $dlvId)
  }
`;

export const ResendInvoice = gql`
  mutation ResendInvoiceMutation($invoiceNo: Int!, $domain: String!) {
    resendInvoice(invoiceNo: $invoiceNo, domain: $domain)
  }
`;

export const AddGha = gql`
  mutation AddGhaMutation(
    $country: String!
    $region: String!
    $title: String!
  ) {
    addGha(country: $country, region: $region, title: $title)
  }
`;

export const AddGsa = gql`
  mutation AddGsaMutation(
    $gsaName: String!
    $countryCode: String!
    $continent: String!
  ) {
    addGsa(gsaName: $gsaName, countryCode: $countryCode, continent: $continent)
  }
`;


export const DeleteRates = gql`
  mutation DeleteRatesMutation(
    $ids: [Int!]! 
    $disselectedIds: [Int!]!
    $selectedAll: Boolean!,
    $accountId: Int!
  ) {
    deleteRates(ids: $ids, disselectedIds: $disselectedIds, selectedAll: $selectedAll, accountId: $accountId)
  }
`;


export const UpdateGha = gql`
  mutation UpdateGhaMutation(
    $country: String!
    $region: String!
    $title: String!
    $ghaId: Int!
    $shortName: String!
  ) {
    updateGha(
      country: $country
      region: $region
      title: $title
      ghaId: $ghaId
      shortName: $shortName
    )
  }
`;

export const UpdateGsa = gql`
  mutation UpdateGsaMutation(
    $gsaId: Int!
    $gsaName: String!
    $countryCode: String!
    $continent: String!
  ) {
    updateGsa(
      gsaId: $gsaId
      gsaName: $gsaName
      countryCode: $countryCode
      continent: $continent
    )
  }
`;

export const DeleteGha = gql`
  mutation DeleteGhaMutation($ghaId: Int!) {
    deleteGha(ghaId: $ghaId)
  }
`;

export const DeleteGsa = gql`
  mutation DeleteGsaMutation($gsaId: Int!) {
    deleteGsa(gsaId: $gsaId)
  }
`;

export const InsertSurcharge = gql`
  mutation InsertSurchargeMutation(
    $accountId: Int!
    $airlineName: String!
    $chargeRule: String!
    $chargesType: String!
    $destination: String!
    $flight: String!
    $startDate: String!
    $endDate: String!
    $origin: String!
    $soldMax: Float!
    $soldMin: Float!
    $soldRate: Float!
  ) {
    insertSurcharges(
      accountId: $accountId
      airlineName: $airlineName
      startDate: $startDate
      endDate: $endDate
      chargeRule: $chargeRule
      chargesType: $chargesType
      destination: $destination
      flight: $flight
      origin: $origin
      soldMax: $soldMax
      soldMin: $soldMin
      soldRate: $soldRate
    )
  }
`;

export const UpdateSurcharge = gql`
  mutation UpdateSurchargeMutation(
    $accountId: Int!
    $airlineName: String!
    $chargeRule: String!
    $chargesType: String!
    $destination: String!
    $flight: String!
    $startDate: String!
    $endDate: String!
    $origin: String!
    $soldMax: Float!
    $soldMin: Float!
    $soldRate: Float!
    $surchargeId: Int!
  ) {
    updateSurcharges(
      surchargeId: $surchargeId
      accountId: $accountId
      airlineName: $airlineName
      startDate: $startDate
      endDate: $endDate
      chargeRule: $chargeRule
      chargesType: $chargesType
      destination: $destination
      flight: $flight
      origin: $origin
      soldMax: $soldMax
      soldMin: $soldMin
      soldRate: $soldRate
    )
  }
`;

export const DeleteSurcharge = gql`
  mutation DeleteSurchargeMutation($surchargeId: Int!) {
    deleteSurcharge(surchargeId: $surchargeId)
  }
`;

export const UpdateGsaUser = gql`
  mutation UpdateGsaUserMutation(
    $userid: Int!
    $title: String!
    $firstname: String!
    $surname: String!
    $countrycode: String!
    $emailid: String!
    $globalemailid: String!
    $phonenumber: String!
    $password: String!
    $cassno: String!
    $iatano: String!
    $fiatano: String!
    $usertype: String!
  ) {
    updateGsaUser(
      userid: $userid
      title: $title
      firstname: $firstname
      surname: $surname
      countrycode: $countrycode
      emailid: $emailid
      globalemailid: $globalemailid
      phonenumber: $phonenumber
      password: $password
      cassno: $cassno
      iatano: $iatano
      fiatano: $fiatano
      usertype: $usertype
    )
  }
`;

export const CreateCountryAssociation = gql`
  mutation CreateCountryAssociationMutation(
    $userId: Int!
    $homeCountry: String!
    $countriesAssociated: [String]!
  ) {
    createCountryAssociation(
      userId: $userId
      homeCountry: $homeCountry
      countriesAssociated: $countriesAssociated
    )
  }
`;

export const UpdateCountryAssociation = gql`
  mutation UpdateCountryAssociationMutation(
    $userId: Int!
    $homeCountry: String!
    $countriesAssociated: [String]!
    $previousHomeCountry: String!
  ) {
    updateCountryAssociation(
      userId: $userId
      homeCountry: $homeCountry
      countriesAssociated: $countriesAssociated
      previousHomeCountry: $previousHomeCountry
    )
  }
`;

export const DeleteCountryAssociation = gql`
  mutation DeleteCountrAssoc($homeCountry: String!) {
    deleteCountrAssoc(homeCountry: $homeCountry)
  }
`;

export const TurnGsaRates = gql`
  mutation TurnGsaRates($userId: Int!, $ratesTurned: Boolean!) {
    turnGsaRates(userId: $userId, ratesTurned: $ratesTurned)
  }
`;
