import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Header from "src/Components/Header";
import { LandingContent } from "./styled";
import { renderToString } from "react-dom/server";
import Footer from "src/Components/Footer";
import "react-multi-carousel/lib/styles.css";
import svgMap from 'src/Components/Map/svgmap/dist/svgMap';
import "src/Components/Map/svgmap/dist/svgMap.css";
import { GetGssaCountryList, GetToolTip } from 'src/Reducers/queries'
import { useLazyQuery } from "@apollo/client";
import { GetGssaCountryListQuery } from 'src/Reducers/types/generated/GetGssaCountryListQuery';
import ReactLoading from "react-loading";
import { countryDataType, FormatedCountryDataType, State, tempCountryDataType } from "src/Pages/Landing/types";
import InfoModal from "src/Components/InfoModal";
import { useAuth } from "src/hooks";
import { IntroOverlay } from "src/Components/IntroOverlay";
import LandingPlane from "src/Assets/Images/plane.png"
// import PlaneBackground from "src/Assets/Images/plane.jpg"
import PlaneBackground from "src/Assets/Images/compressed_plane.png"
import MapImage from "src/Assets/Images/output-onlinepngtools.png"
import SearchImage from "src/Assets/Images/search.jpg"
import TxImage from "src/Assets/Images/Transactions.jpg"
import BulletPng from "src/Assets/Images/bullet.png"


const Landing = () => {
  const auth = useAuth();
  const sessionTimeout = localStorage.getItem('sessionTimeout')
  const loginAttempt = auth?.userConfig?.loginAttempt;
  const [countryData, setCountryData] = useState<countryDataType>([]);
  const [getGssaCountryList, gssaCountryResponse] = useLazyQuery<GetGssaCountryListQuery>(GetGssaCountryList, {
    fetchPolicy: 'no-cache'
  });
  const [formattedCountryData, setFormattedCountryData] = useState<FormatedCountryDataType>({});
  const [mapLoader, setMapLoader] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getGssaCountryList()
    if (loginAttempt === 1) {
      setOpen(true)
    }
  }, [])
  useEffect(() => {
    if (gssaCountryResponse?.data?.getGssaCountryList.length || [].length > 0) {
      setCountryData(JSON.parse(gssaCountryResponse?.data?.getGssaCountryList[0]?.data || ""))
    }
  }, [gssaCountryResponse?.data?.getGssaCountryList])


  useEffect(() => {
    const getCountryData = () => {
      const mainArray: tempCountryDataType = {};
      countryData.map((country) => {
        Object.keys(country).map((key) => {
          mainArray[key] = {
            "Gssa": renderToString(<> {
              country[key]?.map((item) => {
                return <Box>{item}</Box>
              })
            }</>),
          }
        })
      })
      return mainArray;
    }
    if (Object.keys(getCountryData()).length > 0) {
      setFormattedCountryData(getCountryData());
      setMapLoader(false)
    }
  }, [countryData])


  useEffect(() => {
    if (Object.keys(formattedCountryData).length > 0) {
      new svgMap({
        targetElementID: 'svgMap',
        hideFlag: true,
        colorNoData: "#CCC6C6",
        colorMax: "#F5C003",
        colorMin: "#F5C003",
        data: {
          data: {
            Gssa: {
              name: '',
            },
          },
          applyData: 'Gssa',
          values: formattedCountryData,
        }
      });
    }
  }, [formattedCountryData])

  const [values, setValues] = useState<State>({
    email: "",
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // auth.setUserConfig(null);

  const carouselItem1 = [{
    title: "Forwarder",
    description: "Looking for transparent access to relevant information along your procurement journey? Find available routes, request a quote, make a booking and track your shipments with ALL airlines using a single tool.",
  }, {
    title: "Airline",
    description: "Embrace digital change and maximize your distribution strategy. Do you already have available APIs? All integration efforts happen on CargoAi’s side, promised! Not too sure what APIs are? We have solutions for you as well!",
  }, {
    title: "GSA",
    description: "Representing multiple airlines? Get access to the same services as an airline. Representing multiple airlines? Get access to the same services as an airline.",
  }, {
    title: "Airline",
    description: "Embrace digital change and maximize your distribution strategy. Do you already have available APIs? All integration efforts happen on CargoAi’s side, promised! Not too sure what APIs are? We have solutions for you as well!",
  },
  ];

  const carouselItem2 = [
    'Route',
    'eQuote',
    'eBooking',
    'Rates',
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30
    }
  };
  const [dynamicId, setDynamicId] = useState("")
  useEffect(() => {
    const getScrollLinkId = (number) => {
      switch (number) {
        case 0:
          return "howitworks"
        case 1:
          return "forairlines"
        case 2:
          return "customers"
        default:
          return "";
      }
    }
    setDynamicId(getScrollLinkId(value))
  }, [value])
  const [getToolTip, toolTipData] = useLazyQuery(GetToolTip);
  return (
    <>
      <IntroOverlay open={open} handleClose={handleClose} />
      <Header handleChange={handleChange} setValue={setValue} value={value} getToolTip={getToolTip} toolTipData={toolTipData} />
      <InfoModal
        open={sessionTimeout === null ? false : true}
        header=''
        onClose={() => localStorage.removeItem('sessionTimeout')}
      >
        <Typography sx={{ color: 'rgba(5,21,81,0.9)', fontSize: '18px' }}>{`Your logged-in session has expired. Please login to pick up where you left off`}</Typography>
      </InfoModal>
      
      <Box sx={{px: 3, backgroundColor: "#FDAE15", marginTop: "98px" }}>
        <Grid container spacing={4} alignItems="center">
          {/* First Block with LandingPlane as background */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                height: "100%",
                borderRadius: "8px",
                textAlign: "center",
                backgroundImage: `url(${LandingPlane})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                minHeight: 550,
                position: "relative",
                top: "61px"
              }}
            >
<Typography
  variant="h4"
  sx={{
    fontWeight: "bold",
    fontSize: { xs: "40px", sm: "50px", md: "60px" },
    marginBottom: "16px",
    textAlign: "left",
    color: "#2d347a",
    paddingTop: { xs: "20px", sm: "30px", md: "35px" },
  }}
>
  TRICARGO
</Typography>
<Typography
  variant="h6"
  sx={{
    marginBottom: "16px",
    fontWeight: 400,
    textAlign: "left",
    fontSize: { xs: "18px", sm: "22px", md: "26px" },
  }}
>
  The only independent <br />Air Cargo Sales and <br />Booking Solution
</Typography>
<Typography
  variant="body2"
  sx={{
    fontWeight: "bold",
    textAlign: "left",
    fontSize: { xs: "12px", sm: "13px", md: "12px" },
  }}
>
  Security | Privacy | Transparency
</Typography>
            </Box>
          </Grid>
          {/* Second and Third Blocks sharing a single background */}
          <Grid item xs={12} md={8}>
        <Box
          sx={{
            borderRadius: "8px",
            backgroundImage: `url(${MapImage})`,
            backgroundSize: "750px 380px",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            // padding: "24px",
            color: "#1A1A1A",
            minHeight: 500,
            display: "flex",
            flexDirection: "column",  
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
            {/* Second Block Content */}
            <Box sx={{ flex: 1, paddingRight: "16px", marginTop: "190px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "16px"}}>
                FORWARDERS
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <Box
                  component="img"
                  src={BulletPng}
                  alt="Plane icon"
                  sx={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                <Typography sx={{color: "#2d347a", fontSize: "18px"}} variant="body1">Connect with 250+ GSA’s worldwide</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <Box
                  component="img"
                  src={BulletPng}
                  alt="Plane icon"
                  sx={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                <Typography variant="body1" sx={{color: "#2d347a", fontSize: "18px", marginTop: "8px"}}>
                  INSTANT accurate live rates comparisons and booking request
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <Box
                  component="img"
                  src={BulletPng}
                  alt="Plane icon"
                  sx={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                <Typography sx={{color: "#2d347a", fontSize: "18px"}} variant="body1">
                    It’s FREE. Sign-up, Search, Receive offers
                  </Typography>
              </Box>
            </Box>

            {/* Third Block Content */}
            <Box sx={{ flex: 1, paddingLeft: "16px", marginTop: "190px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "16px"}}>
                CARGO GSAs
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <Box
                  component="img"
                  src={BulletPng}
                  alt="Plane icon"
                  sx={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                <Typography variant="body1" sx={{color: "#2d347a", fontSize: "18px", }}>Drive sales with new enquiries</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <Box
                  component="img"
                  src={BulletPng}
                  alt="Plane icon"
                  sx={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                <Typography variant="body1" sx={{color: "#2d347a", fontSize: "18px", marginTop: "8px"}}>
                  ALL Forwarders are manually approved via FIATA membership
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <Box
                  component="img"
                  src={BulletPng}
                  alt="Plane icon"
                  sx={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                <Typography sx={{color: "#2d347a", fontSize: "18px"}} variant="body1">
                Fixed small flat fee per completed transaction
                  </Typography>
              </Box>
            </Box>
          </Box>

          {/* Bottom Text Sharing Background */}
          <Typography
            variant="body2"
            sx={{
              marginTop: "24px",
              textAlign: "left", // Aligns the text to the left
              color: "#1A1A1A",
              fontSize: "18px",
              fontWeight: 400,
              marginBottom: "50px"
            }}
          >
            Created by FEDAGSA - Federation of Airline General Sales Agents, a Swiss, neutral, non-profit
            organisation, for our members and others, to offer extensive price and availability comparisons
            in the market. No data will ever be sold or shared.
          </Typography>
        </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{
        height: "70px",
        backgroundColor: "#e8e8e8 !important",
        // position: "relative",
        // top: "-110px"
      }}>

      </Box>
      <Box
        sx={{
          py: 5,
          backgroundImage: `url(${PlaneBackground})`, 
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          color: "#fff", 
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          alignItems: "flex-start",
          paddingTop: "1rem",
        }}
      >
    <Grid container spacing={4} alignItems="center" justifyContent="center">
      {/* First Image with Text */}
      <Grid item xs={12} md={6} sx={{ textAlign: "center", marginTop: "70px" }}>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            fontWeight: "bold",
            // textTransform: "uppercase",
            color: "#2d347a"
          }}
        >
          Forwarder: Search Results
        </Typography>
        <Box
          component="img"
          src={SearchImage}
          alt="Search Results"
          sx={{
            width: "90%",
            maxWidth: "450px",
            aspectRatio: "16 / 9",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            
          }}
        />
      </Grid>

    {/* Second Image with Text */}
    <Grid item xs={12} md={6} sx={{ textAlign: "center",  marginTop: "70px" }}>
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          fontWeight: "bold",
          // textTransform: "uppercase",
          color: "#2d347a"
        }}
      >
        GSA: Quoting/Booking
      </Typography>
      <Box
        component="img"
        src={TxImage}
        alt="Quoting/Booking"
        sx={{
          width: "90%",
          maxWidth: "450px",
          aspectRatio: "16 / 9",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          
        }}
      />
    </Grid>
  </Grid>
      </Box>

      <LandingContent>
        <Box className="landing_page_wrp" sx={{marginBottom: "20px"}}>
          <Box className="location_map" >
            <Box className="lds-wrp">
              <Grid className="banner_wrp" container spacing={2}>
                <Grid item xs={12} md={4} sm={4} lg={4}>
                  <Box className="side_content-wrapper">
                    <Box className="map_side_content">
                      <Typography className="header_content">FEDAGSA global members</Typography>
                      <Typography className="main_content">
                      The Federation of Airline General Sales Agents (FEDAGSA), Geneva, Switzerland is a not for profit trade federation. We are absolutely dedicated to representing the interests of our airline GSSA's throughout the world. Following  more than 33 years representing our members interests and providing very popular supporting services the Federation continues to attract new members. We provide much needed help and significant practical benefits and our board representatives are now located around the globe
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", color: "#2d347a", textDecoration: "underline", marginTop: "15px" }}
                      >
                        Federation of Airline General Sales Agencies
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#1A1A1A" }}
                    >
                      c/o IATA, Route de l'Aeroport 33, <br></br>1215 Geneve, Switzerland
                    </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sm={8} lg={7}>
                  {mapLoader ?
                    <Box className="lds-inner-wrp"><Box className="lds-ripple"><ReactLoading type={"bars"} color="#3f83f1" />
                    </Box></Box> :
                    <Box id="svgMap">
                      <svg style={{ position: 'absolute' }}>
                        <defs>
                          <filter id="continent-border" x="-50%" y="-50%" width="200%" height="200%">
                            <feMorphology in="SourceAlpha" operator="dilate" radius="4" result="dilated" />
                            <feFlood flood-color="#2d347a" result="border-color" />
                            <feComposite in="border-color" in2="dilated" operator="in" />
                            <feMerge>
                              <feMergeNode />
                              <feMergeNode in="SourceGraphic" />
                            </feMerge>
                          </filter>
                        </defs>
                      </svg>
                    </Box>
                  }
                </Grid>
                <Box className="map_info_text"><Typography sx={{color: "#2d347a"}}>(Click continents to view current members list)</Typography></Box>
                <Box className="map_info"><Box className="map_button"></Box> <Typography>Fedagsa Members</Typography></Box>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer />
      </LandingContent >
    </>
  );
};
export default Landing;
